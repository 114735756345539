@font-face {
    font-family: 'HalvarEng';
    src: url('../fonts/HalvarEng-Rg/HalvarEng-Rg.woff2') format('woff2'),
        url('../fonts/HalvarEng-Rg/HalvarEng-Rg.woff') format('woff'),
        url('../fonts/HalvarEng-Rg/HalvarEng-Rg.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'HalvarEng';
    src: url('../fonts/HalvarEng-Lt/HalvarEng-Lt.woff2') format('woff2'),
        url('../fonts/HalvarEng-Lt/HalvarEng-Lt.woff') format('woff'),
        url('../fonts/HalvarEng-Lt/HalvarEng-Lt.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HalvarEng';
    src: url('../fonts/HalvarEng-Md/HalvarEng-Md.woff2') format('woff2'),
        url('../fonts/HalvarEng-Md/HalvarEng-Md.woff') format('woff'),
        url('../fonts/HalvarEng-Md/HalvarEng-Md.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HalvarEng';
    src: url('../fonts/HalvarEng-Bd/HalvarEng-Bd.woff2') format('woff2'),
        url('../fonts/HalvarEng-Bd/HalvarEng-Bd.woff') format('woff'),
        url('../fonts/HalvarEng-Bd/HalvarEng-Bd.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HalvarEng';
    src: url('../fonts/HalvarEng-RgSlanted/HalvarEng-RgSlanted.woff2') format('woff2'),
        url('../fonts/HalvarEng-RgSlanted/HalvarEng-RgSlanted.woff') format('woff'),
        url('../fonts/HalvarEng-RgSlanted/HalvarEng-RgSlanted.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Halvar Breitschrift';
    src: url('../fonts/HalvarBreit-Md/HalvarBreit-Md.woff2') format('woff2'),
        url('../fonts/HalvarBreit-Md/HalvarBreit-Md.woff') format('woff'),
        url('../fonts/HalvarBreit-Md/HalvarBreit-Md.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'icomoon';
    src: url('../fonts/icomoon/icomoon/fonts/icomoon.eot');
    src: url('../fonts/icomoon/icomoon/fonts/icomoon.eot?#iefix') format('embedded-opentype'),
        url('../fonts/icomoon/icomoon/fonts/icomoon.woff') format('woff'),
        url('../fonts/icomoon/icomoon/fonts/icomoon.svg#icomoon') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}


[class^="icon-"],
[class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
