.header {
    z-index: 10;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    z-index: 100;
    color: #000;
    -webkit-transition: -webkit-box-shadow .3s ease;
    transition: -webkit-box-shadow .3s ease;
    -o-transition: box-shadow .3s ease;
    transition: box-shadow .3s ease;
    transition: box-shadow .3s ease, -webkit-box-shadow .3s ease;
}

.header .wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.header .wrapper:after {
    display: none;
}


.header-navs {
    display: flex;
    align-items: center;
}

.logo {
    display: inline-block;
    width: 21.2rem;
    vertical-align: top;
}


.bars-mob {
    margin-right: 10px;
    width: 2rem;
    height: 2rem;
    display: none;
    padding: .5rem;
    position: relative;
    cursor: pointer;
    margin-top: -8px;
}

.bars-mob .hamburger {
    position: absolute;
    height: 2rem;
    width: 2rem;
    left: 0;
}

.bars-mob .hamburger span {
    display: block;
    width: 100%;
    height: .2rem;
    position: relative;
    background: #fff;
    top: 0;
    left: 0;
    margin: 0 0 .5rem;
    -webkit-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
}

.bars-mob .hamburger span:nth-child(1) {
    -webkit-transition-delay: .5s;
    -o-transition-delay: .5s;
    transition-delay: .5s;
}

.bars-mob .hamburger span:nth-child(2) {
    -webkit-transition-delay: .625s;
    -o-transition-delay: .625s;
    transition-delay: .625s;
}

.bars-mob .hamburger span:nth-child(3) {
    -webkit-transition-delay: .75s;
    -o-transition-delay: .75s;
    transition-delay: .75s;
}

.bars-mob .cross {
    position: absolute;
    height: 2rem;
    width: 2rem;
    margin: 0 0 0 2px;
    -webkit-transform-origin: center center;
    -ms-transform-origin: center center;
    transform-origin: center center;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.bars-mob .cross span {
    display: block;
    background: #fff;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
}

.bars-mob .cross span:nth-child(1) {
    height: 100%;
    width: .2rem;
    position: absolute;

    -webkit-transition-delay: 0s;
    -o-transition-delay: 0s;
    transition-delay: 0s;
}

.bars-mob .cross span:nth-child(2) {
    width: 100%;
    height: .2rem;
    position: absolute;

    -webkit-transition-delay: .25s;
    -o-transition-delay: .25s;
    transition-delay: .25s;
}

.bars-mob.active .hamburger span {
    width: 0%;
}

.bars-mob.active .hamburger span:nth-child(1) {
    -webkit-transition-delay: 0s;
    -o-transition-delay: 0s;
    transition-delay: 0s;
}

.bars-mob.active .hamburger span:nth-child(2) {
    -webkit-transition-delay: .125s;
    -o-transition-delay: .125s;
    transition-delay: .125s;
}

.bars-mob.active .hamburger span:nth-child(3) {
    -webkit-transition-delay: .25s;
    -o-transition-delay: .25s;
    transition-delay: .25s;
}

.bars-mob.active .cross span:nth-child(1) {
    height: 100%;
    -webkit-transition-delay: .625s;
    -o-transition-delay: .625s;
    transition-delay: .625s;
}

.bars-mob.active .cross span:nth-child(2) {
    width: 100%;
    -webkit-transition-delay: .375s;
    -o-transition-delay: .375s;
    transition-delay: .375s;
}

.header-main .header-wrapper {
    padding-top: 1.6rem;
    padding-bottom: 1rem;
}

.btn_login {
    margin: 0 0 0 1rem;
    height: 3.6rem;
    line-height: 3.4rem;
    font-size: 1.8rem;
    font-family: HalvarEng, arial, sans-serif;
    font-weight: 400
}

.btn_login.btn-2 {
    color: #0A0A0A;
}

.btn_login.btn-2:hover {
    color: #fff;
}

.header-menu {
    border-top: 1px solid rgba(212, 212, 212, .1);
}

.main-menu {
    width: 100%;

    ul {
        margin: 0 -1rem;
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    li {
        margin: 0 .5rem;
        display: inline-flex;
        align-items: center;
    }

    a {
        font-size: 18px;
        line-height: 21px;
        color: #fff;
        min-height: 4.8rem;
        padding: 0 15px;
        display: inline-flex;
        align-items: center;
        text-transform: uppercase;
        transition: color .3s ease
    }

    li svg {
        fill: $color-active;
        margin-right: 1rem;
        transition: fill .3s ease
    }

    .current-menu-item a {
        background: linear-gradient(180deg, rgba(175, 21, 21, 0) 0%, $color-active 100%);

        svg {
            fill: #fff;
        }
    }

    a:hover {
        color: $color-active;

        svg {
            fill: #fff;
        }
    }

    .current-menu-item a,
    .current-menu-item a:hover {
        color: #fff;

        svg {
            fill: #fff;
        }
    }
}


.dropdown-header {
    margin-left: 20px
}

.dropdown {
    position: relative;
}

.login-ic {
    width: 46px;
    height: 36px;
    color: #fff;
    font-size: 20px;
    background: #0A0A0A;
    border: 1px solid $color2;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: color .5s ease, border-color .5s ease, background .5s ease;
}

.login-ic:hover {
    background: #D4D4D4;
    color: #0A0A0A;
    border: 1px solid #333333;
}

.dropdown-trigger {
    cursor: pointer;
}

.dropdown-body {
    display: none;
    font-size: 14px;
    line-height: 20px;
    border-radius: 10px;
    position: absolute;
    right: -10px;
    top: 100%;
    min-width: 100%;
    width: max-content;
    z-index: 100;
    background: #0A0A0A;
    border: 1px solid $color2;
    box-shadow: 0px 20px 25px rgba(0, 0, 0, 0.1), 0px 10px 10px rgba(0, 0, 0, 0.04);
    border-radius: 10px;
}

[data-open="true"] .dropdown-body {
    display: block;
}

.header-menu-profile {
    list-style: none;
}

.header-menu-profile a {
    font-size: 18px;
    line-height: 21px;
    padding: 14px 20px;
    display: flex;
    align-items: center;
}

.header-menu-profile a i {
    margin-right: 10px;
    color: #333;
    font-size: 20px;
}

.header-menu-profile li+li {
    border-bottom: 1px solid rgba(255, 255, 255, .1);
}

.header-btns {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.header-balance {
    margin-right: 10px;
    color: #333;
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 19px;

}

.header-balance+.header-balance {
    position: relative;
    padding: 0 0 0 14px;
    margin: 0 0 0 3px;
}

.header-balance+.header-balance:before {
    content: '';
    display: block;
    position: absolute;
    width: 1px;
    height: 16px;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background: #333;
}

.header-balance__text2 {
    margin-left: 8px
}

.header-balance__text2 i {
    margin-left: 5px;
    vertical-align: middle;
}

.header-balance__text {
    color: #fff
}

.color-red {
    color: $color-active;
}

.color-green {
    color: #50BC1D;
}

.header-balance__ic {
    color: #333;
    margin-right: 10px;
    font-size: 25px;
    display: flex;
    align-items: center;
}

@media screen and (max-width: 1023px) {
    .logo {
        width: 17.2rem;
    }
}

@media screen and (max-width: 767px) {
    .header-main .header-wrapper {
        padding-top: 1.2rem;
        padding-bottom: 1rem;
    }

    .logo {
        width: 13rem
    }

    .btn_login {
        height: 3.2rem;
        line-height: 3rem;
        padding: 0 0.7rem;
        min-width: 5.6rem
    }

    .main-menu {
        li {
            margin: 0;
        }

        li svg {
            width: 1.6rem;
            height: 1.6rem;
            margin-right: .4rem
        }

        a {
            padding: 0 .5rem;
            font-size: 1.5rem;
            line-height: 1.7rem;
            min-height: 4.6rem;
        }
    }


    .bars-mob {
        display: block;
    }

    .open-header .header {
        z-index: 0
    }

    .bars-mob_cross {
        position: absolute;
        z-index: 1;
        top: 19px;
        left: 9px;
    }

    .overlay {
        position: fixed;
        pointer-events: none;
        transition: all .5s ease;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background: #0A0A0A;
        z-index: 100;
        opacity: 0;
    }

    .overlay.shown {
        pointer-events: auto;
        opacity: 0.8;
    }

    .header-balance {
        font-size: 14px;
        line-height: 16px;
    }

    .menu-aside .aside-menu ul {
        display: block;
        width: 100%
    }

    .menu-aside .aside-menu ul a {
        font-size: 16px;
        line-height: 19px;
        padding: 1rem 1.2rem 1rem 1rem;
        display: flex;
        align-items: center;
        flex-direction: row;
    }

    .aside-menu ul svg {
        margin: 0 10px 0 0;
    }

    .header-menu-profile {
        margin-top: 3px;
    }

    .header-menu-profile a {
        font-size: 18px;
        line-height: 21px;
        padding: 10px;
    }

    .header-balance__ic {
        display: none;
    }

    .header-btns {
        margin-left: auto;
        display: block;
        text-align: right;
    }

    .header-balance {
        margin: 0;
        justify-content: flex-end;
    }

    .header-balance+.header-balance {
        padding: 0;
        margin: 0;
    }

}

@media screen and (max-width: 374px) {
    .header-balance {
        font-size: 13px;
        line-height: 15px;
    }

    .logo {
        width: 10rem;
    }

    .main-menu a {
        font-size: 13px;
        line-height: 16px
    }
}