.section-main {
	padding-bottom: 93px;
}

.main-block {
	display: flex;
	flex-wrap: wrap;
}

.main-slider {
	margin-bottom: 2.4rem;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: cover;
	width: 100%
}

.main-slider__item {
	position: relative;
}

.main-slider__item-bg {
	background-position: 0 50%;
	background-repeat: no-repeat;
	background-size: cover;
	z-index: 0;
	position: absolute;
	left: 29.5%;
	top: 0;
	right: 0;
	bottom: 0
}

.main-slider__item .wrapper {
	display: flex;
	min-height: 39.2rem;
	z-index: 1
}

.slick-initialized .main-slider__item {
	display: flex;
}

.main-slider__item:hover {
	color: #fff;
}

.main-slider__heading {
	padding: 5rem 3rem 5rem 0;
	width: 50%;
	display: flex;
	justify-content: center;
	flex-direction: column;
}

.main-slider__img {
	width: 50%;
	display: flex;
	justify-content: flex-end;
	align-items: flex-start;
	flex-direction: column;
}

.main-slider__img img {
	vertical-align: top;
}

.main-slider__title {
	font-weight: 700;
	font-size: 4.6rem;
	line-height: 5.3rem;
	text-transform: uppercase;
	margin-bottom: 1rem;
}

.main-slider__text {
	font-size: 20px;
	line-height: 23px;
}

.main-slider__btn {
	margin-top: 3rem
}

.main-slider__note {
	margin-top: 2.9rem;
	font-size: 14px;
	line-height: 16px;
}

.main-slider .slick-dots {
	margin: 0;
	position: absolute;
	left: 5rem;
	bottom: 1.8rem;
	z-index: 10;
	width: auto
}

.main-block-content {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
}

.aside-navs {
	width: 18.3rem
}

.content-middle {
	padding: 0 2rem 0 0;
	width: calc(100% - 18.3rem);
}

.content-middle__middle {
	padding: 0 0 0 2rem;
	width: calc(100% - 18.3rem);
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
}

.card-items {
	margin: 0 -1rem;
}

.card-item {
	margin: 0 1rem 2rem;
	background: #0F0F0F;
}

.card-item__head {
	position: relative;
	padding: 12px 12px 12px 15px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: rgba(51, 51, 51, .2)
}

.card-item__head:before {
	content: '';
	display: block;
	background: $color-active;
	width: .3rem;
	height: 2rem;
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);

}

.card-item__body {
	padding: 8px 9px 14px 12px;
}

.card-item__title {
	font-weight: 500;
	font-size: 18px;
	line-height: 21px;
	margin-right: 1.5rem;
}

.card-item__date {
	font-size: 16px;
	line-height: 19px;
	color: #D4D4D4;
}

.card-item__table {
	display: table;
	table-layout: fixed;
	width: 100%
}

.card-item__row {
	display: table-row;
}

.card-item__row+.card-item__row .card-item__col {
	padding-top: 15px;
	border-top: 1px solid rgba(51, 51, 51, .1)
}

.card-item__row-head+.card-item__row .card-item__col {
	padding-top: 0;
	border-top-width: 0
}

.card-item__col {
	padding: 0 .3rem 11px;
	width: 3.6rem;
	display: table-cell;
	vertical-align: middle;
	text-align: center;
}

.card-item__col:first-child {
	text-align: left;
	width: calc(100% - 21.6rem);
}

.card-item__row-head .card-item__col {
	color: #D4D4D4;
	opacity: .5
}

.card-item__name {
	font-size: 1.6rem;
	line-height: 1.16;
}

.card-item__item {
	background: #0A0A0A;
	border: 1px solid rgba(80, 78, 79, 0.3);
	height: 4.4rem;
	width: 3rem;
	border-radius: 6px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.card-item__item i {
	color: #333;
	font-size: 16px;
}

.card-item__item:hover {
	background: #D4D4D4;
	color: #0A0A0A;
}

.card-item__item.disabled {
	pointer-events: none;
	border-color: transparent;
	background: transparent;
}


.aside-menu {
	background: #0F0F0F;
}

.aside-menu ul {
	list-style: none
}

.aside-menu ul a {
	font-size: 16px;
	line-height: 19px;
	padding: 1rem 1.2rem 1rem 1rem;
	display: flex;
	align-items: center;
	border-bottom: 1px solid rgba(255, 255, 255, .1);
	transition: color .5s ease;
}

.aside-menu ul svg {
	fill: $color-active;
	margin-right: 10px;
}

.aside-menu ul .current-menu-item a,
.aside-menu ul a:hover {
	color: $color-active;
}

.aside-menu ul .current-menu-item a {
	border-right: 3px solid $color-active;
}

.aside-menu__count {
	font-size: 14px;
	line-height: 16px;
	text-align: right;
	color: $color2;
}

.aside-menu_2 ul a {
	justify-content: space-between;
}

.aside-menu:last-child ul li:last-child a {
	border-bottom-width: 0
}

.aside-menu__heading {
	font-size: 14px;
	line-height: 16px;
	font-style: italic;
	color: $color2;
	padding: 10px 1rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.promo {
	margin-top: 20px;
	position: relative;
}

.tab-nav {
	display: flex;
}

.tab-nav__link {
	background: rgba(51, 51, 51, .2);
	padding: 10px .5rem;
	font-weight: 500;
	font-size: 18px;
	line-height: 21px;
	flex: 1 1 100%;
	text-align: center;
	cursor: pointer;
	border-bottom: .3rem solid rgba(51, 51, 51, .2);
	transition: border-color .5s ease, background .5s ease,
}

.tab-nav__link.active {
	background: #333;
	border-color: $color-active;
}

.tab-nav__link:hover {
	background: #333;
}

.tabs-aside {
	background: #0F0F0F;
}

.aside-tab {
	padding: 13px 1rem 29px;
}

.note-info {
	font-size: 16px;
	line-height: 19px;
	display: flex;
	align-items: center;
}

.note-info svg {
	margin-right: 1rem;
	flex-shrink: 0;
	font-size: 16px;
	line-height: 19px;
}

.note-text {
	margin: 16px 6px 0 0;
	font-size: 14px;
	line-height: 16px;
	color: $color2;
}

.section-heading {
	margin-bottom: 1.8rem;
	background: #0F0F0F;
	width: 100%
}

.menu-aside {
	background: #0F0F0F;

	&__head {
		font-size: 16px;
		line-height: 19px;
	}

	&__title {
		background: rgba(56, 58, 60, .2);
		padding: 10px 10px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		cursor: pointer;
	}

	&__title i {
		color: $color2;
		font-size: 10px;
	}

	&__body {
		display: none;
	}

	ul a {
		color: #FFF;

		&.disabled {
			pointer-events: none;
			color: #333;

			svg {
				fill: #333;
			}
		}
	}

	ul a:hover {
		color: $color-active;
	}

	.aside-menu ul svg {
		fill: #504E4F;
	}

	&.active .menu-aside__title i {
		transform: rotateX(180deg);
	}

}

.web .menu-aside__title:hover .menu-aside__head {
	color: $color-active;
}

.aside-menu ul .current-menu-item svg {
	fill: $color-active;
}


.content-middle-inner {
	width: calc(100% - 36.6rem);
	padding: 0 2rem
}

.section-inner {
	position: relative;
	background: #0A0A0A;
	padding: 10px 0 130px;
}

.section-inner__bg {
	position: relative;
	opacity: 0.08;
	filter: grayscale(1) brightness(83%) contrast(141%);
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	background-size: cover;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-attachment: fixed;
	z-index: 0;
	pointer-events: none;
}

.section-inner .main-block {
	margin-top: 20px;
}

.section-inner .main-block {
	position: relative;
	z-index: 1
}

.table {
	display: table;
	width: 100%;
	table-layout: fixed;
}

.table__row {
	display: table-row;

	&.js-fancybox {
		cursor: pointer;
	}
}

.table__td {
	padding: 12px 7px 12px 15px;
	display: table-cell;

	&:nth-child(1) {
		width: 15%
	}

	&:nth-child(2) {
		width: 21%;
	}

	&:nth-child(3) {
		width: 9%;
	}

	&:nth-child(4) {
		width: 9%;
	}

	&:nth-child(5) {
		width: 11%;
	}

	&:nth-child(6) {
		width: 11%;
	}

	&:nth-child(7) {
		width: 12%;
	}
}

.table__row_head .table__td {
	background: rgba(51, 51, 51, .3);
	font-weight: 500;
	font-size: 18px;
}

.table-wins {
	font-size: 16px;
	line-height: 19px;
	position: relative;
}

.table-wins-wrap {

	overflow: auto;
}

.table-clone {
	position: absolute;
	left: 0;
	top: 0;
	opacity: 0;
	pointer-events: none
}

.footer {
	order: 3
}

::-webkit-scrollbar {
	width: 4px;
	height: 4px;
}

::-webkit-scrollbar-track {
	background: #0A0A0A;
}

::-webkit-scrollbar-thumb {
	background: #333
}

::-webkit-scrollbar-thumb:hover {
	background: #555;
}

body.web::-webkit-scrollbar {
	width: 10px;
}

body.ios::-webkit-scrollbar {
	width: 0;
}

.window-open {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	overflow: hidden;
	transform: translate(0, -200%);
}

.heading-count {
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: $color2;
}

.catalog-games-wrapper+.catalog-games-wrapper {
	margin-top: 70px;
}

.catalog-games {
	margin: 0 -10px;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.games-item {
	margin: 0 10px 20px;
	width: calc(16.667% - 20px);

	&__img {
		position: relative;
		margin-bottom: 14px;
		overflow: hidden;
		vertical-align: top;

		&:before {
			content: '';
			display: block;
			padding-top: 75%;
		}

		img {
			height: 100%;
			width: 100%;
			position: absolute;
			vertical-align: top;
			top: 0;
			left: 0;
			transition: transform .5s ease;
		}
	}

	&:hover {
		.games-item__img img {
			transform: scale(1.05);
		}
	}

	&__title {
		font-size: 20px;
		line-height: 23px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		text-align: center;
	}
}

.more-btn {
	margin-top: 27px;
	text-align: center;
}

.note-empty {
	margin-top: 15px;
	font-size: 16px;
	line-height: 19px;
	text-align: center;
}

.article {
	font-size: 14px;
	line-height: 18px;
	color: #504E4F;
	background: #0F0F0F;

	a {
		color: $color-active;

		&:hover {
			color: #fff;
		}
	}

	&__info {
		padding: 29px 0 20px;
		display: none;
	}

	&__title {
		padding: 5px 0;
		color: #fff;
		display: flex;
		align-items: center;
		justify-content: space-between;
		cursor: pointer;

		i {
			font-size: 24px;
			flex-shrink: 0;
			margin-left: 15px;
			color: #504E4F;
			display: inline-block;
			vertical-align: top;
			transition: transform .3s ease, color .3s ease
		}
	}

	&__item {
		padding: 8px 30px 7px;

		&.active {
			.article__title i {
				color: #fff;
				transform: rotateX(180deg);
			}
		}
	}

	p {
		padding: 5px 0;
	}

}

.web .article__title:hover {
	i {
		color: $color-active;
	}
}

.content-middle-inner_satelite {
	width: 100%;
	padding: 0;
	max-height: calc(100vh - 130px);
	overflow: auto;
	background: #0F0F0F;

	.table__row_head .table__td:nth-child(1):before {
		width: 0
	}

	&::-webkit-scrollbar {
		width: 4px;
		height: 4px;
	}

	&::-webkit-scrollbar-track {
		background: inherit;
	}

	&::-webkit-scrollbar-thumb {
		background: #fff
	}

	&::-webkit-scrollbar-thumb:hover {
		background: #555;
	}
}

.btn_login-inner {
	white-space: nowrap;
}

.img-table-logo {
	max-height: 18px;
	vertical-align: top
}

.settings-form {
	padding: 10px 30px 20px;
	background: #0F0F0F;

	form {
		max-width: 361px;
	}
}

.menu-payment {
	list-style: none;

	li {
		padding: 7px 16px 7px 20px;
		font-size: 18px;
		line-height: 21px;
		cursor: pointer;
		border-bottom: 1px solid rgba(#D4D4D4, .1);
		min-height: 47px;
		display: flex;
		align-items: center;
		justify-content: space-between;

		&:hover,
		&.current {
			color: $color-active;
		}
	}
}

.label-payment {

	position: absolute;
	left: 16px;
	top: 17px;
	pointer-events: none;
	color: #D4D4D4;
	opacity: 0.5;
	font-size: 18px;
	line-height: 21px;
	transition: all .5s ease;
}

.dropdown-select {
	position: absolute;
	top: 0;
	right: 0;
	padding-right: 16px;

	.dropdown-trigger {
		height: 54px;
		display: flex;
		align-items: center;
		justify-content: flex-end;

		img {
			max-height: 23px;
			max-width: 93px;
		}
	}

	.dropdown-body {
		right: 0;
		width: calc(100vw - 32px);
		max-width: 361px
	}
}

@media screen and (max-width: 1365px) {
	.main-slider__item .wrapper {
		min-height: 0;
	}

	.main-slider__item {
		background-position: 180% 50%;
	}

	.main-slider__heading {
		padding: 4rem 3rem 4rem 0;
	}

	.main-slider .slick-dots {
		bottom: .8rem
	}
}

@media screen and (max-width: 1280px) {
	.main-slider__title {
		font-weight: 700;
		font-size: 4rem;
		line-height: 4.8rem;
	}

	.main-slider__text {
		font-size: 18px;
		line-height: 21px;
	}

	.card-item__name {
		font-size: 1.4rem;
	}
}

@media screen and (max-width: 1279px) {

	.table-wins {
		font-size: 14px;
		line-height: 17px;
	}

	.games-item {
		width: calc(20% - 20px);
	}
	.section-inner {
	    padding: 10px 0 87px;
	}
}

@media screen and (max-width: 1023px) {
	.content-middle__middle .aside-navs_right {
		order: 2;
	}

	.content-middle {
		margin-top: 2rem;
		padding-right: 0;
		width: 100%;
		order: 3;
	}

	.content-middle__middle .section-heading {
		order: 1;
	}

	.content-middle-inner {
		width: calc(100% - 18.3rem);
		padding-right: 0
	}

	.table__td {
		padding: 12px 7px;
	}

	.games-item {
		width: calc(25% - 20px);
	}

	.content-middle-inner_satelite {
		width: 100%
	}
	.aside-navs_right {
		width: 100%;
		max-width: 450px;
	}
	.promo {
		max-width: 450px
	}
	.promo a {
		display: block;
	}
	.section-inner {
	    padding: 10px 0 77px;
	}
}

@media screen and (max-width: 1023px) and (min-width: 768px) {

	.table-wins {
		font-size: 13px;
		line-height: 18px;
		.table__row_head .table__td {
			font-size: 14px
		}
		.table__td {
		    padding: 12px 5px;
		}
	}
}
@media screen and (max-width: 767px) {
	.section-inner {
		padding-bottom: 50px
	}

	.mob-menu {
		margin: 0 5px;
		display: flex;
		overflow: auto;
	}

	.aside-menu ul {
		display: flex;
	}

	.aside-menu ul a {

		font-size: 12px;
		line-height: 12px;
		padding: 2px 5px 2px;
		text-align: center;
		flex-direction: column;
		align-items: center;
	}

	.aside-menu {
		display: flex;
	}

	.aside-menu__heading {
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-start;
		white-space: nowrap;
	}

	.aside-menu ul svg {
		margin: 0 0.2rem 0 0;
	}

	.aside-menu_main ul .current-menu-item a {
		border-right: 0;
	}

	.aside-menu_2 LI {
		flex: 1 1 100%;
		display: flex;
		flex-direction: column;
	}

	.aside-menu_2 ul a {
		flex: 1 1 100%;
		justify-content: space-between;
	}

	.main-slider {
		margin-bottom: 1rem;
	}

	.main-slider__title {
		font-size: 24px;
		line-height: 26px;
		margin-bottom: 6px;
	}

	.main-slider__heading {
		width: 100%;
		padding: 5px 0 20px 10px;
	}

	.main-slider__img {
		width: 51%;
		position: absolute;
		top: 31%;
		right: 15px;
		bottom: 0
	}

	.main-slider__btn .btn {
		font-size: 14px;
		line-height: 2.5rem;
		height: 2.7rem
	}

	.main-slider__text {
		font-size: 14px;
		line-height: 16px;
		max-width: 61%;
	}

	.main-slider__btn {
		margin-top: 1.3rem;

		.btn {
			padding: 0 1rem
		}
	}

	.main-slider__note {
		margin-top: 1.2rem;
		font-size: 12px;
		line-height: 14px;
	}

	.main-slider .slick-dots {
		bottom: -0.2rem;
		left: 15px;
	}

	.content-middle__middle {
		width: 100%;
		padding-left: 0
	}

	.promo {
		width: 100%
	}

	.aside-navs {
		width: 100%;
	}

	.note-text {
		margin: 10px 6px 0 0;
	}

	.aside-tab {
		padding: 10px 1rem 16px;
	}

	.h2,
	.h2-decor {
		font-size: 16px;
		line-height: 18px;
	}

	.h2-decor {
		padding: 5px 10px 4px;
	}

	.section-heading {
		margin-bottom: 1rem;
	}

	.card-items {
		margin: 0 -1rem 0 -2rem;
		padding: 1px 15px 2px;
		;
		display: block;

		.slick-list {
			overflow: visible;
		}
	}

	.card-item__col {
		width: 3.5rem
	}

	.card-item__col:first-child {
		width: calc(100% - 21rem);
	}

	.card-item {
		height: 100%;
		width: calc(100vw - 3.2rem);
		margin: 0 .4rem 1rem;
		max-width: 34.3rem;
		flex-shrink: 0
	}

	.section-inner__bg {
		background-attachment: scroll
	}

	.aside-navs_inner {
		position: fixed;
		left: 0;
		transform: translateX(-100%);
		top: 0;
		padding-top: 53px;
		margin: 0;
		z-index: 110;
		width: 100%;
		bottom: 0;
		max-width: 32rem;
		overflow: auto;
		will-change: right;
		background: #0A0A0A;
		box-shadow: 0px 20px 25px rgba(0, 0, 0, 0.1), 0px 10px 10px rgba(0, 0, 0, 0.04);
		transition: transform .5s ease;
		bottom: 0;

	}

	.aside-navs_inner.active {
		transform: translateX(0);
	}


	.content-middle-inner {
		width: 100%;
		padding: 0;
	}

	.table-wins {
		font-size: 16px;
		line-height: 19px;
	}

	.table {
		display: flex;
	}

	.table__row_head {
		position: absolute;
		left: 0;
		right: 0;
		opacity: 0;
		pointer-events: none;
	}

	.table__row,
	.table__td {
		display: block;
	}

	.table__row {
		border-left: 2px solid #070707;
	}

	.table__row_head .table__td {
		font-size: 16px;
		background: #1C1C1C;
	}

	.table-clone {
		opacity: 1;
		display: inline-flex;
		width: auto;

		.table__row {
			opacity: 0;
		}

		.table__row_head {
			opacity: 1;
			position: relative;
			flex-shrink: 0
		}

		.table__row-empty {
			opacity: 1;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			width: 100%
		}
	}

	.table-clone_empty {
		width: 100%
	}

	.table__td {
		display: flex;
		flex-direction: column;
		justify-content: center;
		min-height: 43px;
		padding-top: 3px;
		padding-bottom: 3px;
	}

	.table-clone .table__row_head .table__td {
		border-bottom: 1px solid #070707;
		border-top: 1px solid #070707;
	}

	.games-item {
		width: calc(33% - 20px);
	}

	.table__td:nth-child(odd),
	.table__td:nth-child(even) {
		width: 100%
	}

	.section-main {
		padding-bottom: 35px;
	}

	.note-empty {
		display: inline-flex;
		max-width: 80px
	}

	.aside-menu ul .current-menu-item a {
		border-width: 0
	}

	.aside-menu_3 {
		white-space: nowrap;
		margin: -9px 0 4px;
		display: inline-block;
		ul a {
			padding: 5px 10px;
			font-size: 16px;
		}
	}

	.article__item {
		padding: 0 14px 10px;
	}

	.article__item_main {
		padding-bottom: 21px;
	}

	.article__info {
		padding: 18px 0 10px;
	}

	.btn_login-inner {
		font-size: 13px;
		height: 24px;
		line-height: 22px;
		margin-left: -7px;
		padding: 0 13px;
		margin-right: 10px
	}

	.content-middle-inner_satelite {
		.table-clone {
			min-width: 103px
		}
	}

	.content-middle-inner_satelite {
		overflow: visible;
	}

	.table-wins-wrap {
		margin-right: -16px
	}

	.settings-form {
		padding: 4px 16px 0;
	}
	.menu-aside .aside-menu ul svg {
		margin-right: 8px;
	}
}

@media screen and (max-width: 480px) {
	.games-item {
		width: calc(50% - 20px);
	}
}
@media screen and (max-width: 374px) {
	.settings-form {
	    padding: 4px 0 0;
	}
	.box-field__input_payment .form-control {
		padding-left: 20px !important;
	}
	.label-payment {
		display: none;
	}
}
@media screen and (min-width: 768px) {

	.table__row_head .table__td:nth-child(1) {
		position: relative;

		&:before {
			content: '';
			position: absolute;
			background: $color-active;
			top: 50%;
			left: 0;
			width: 3px;
			height: 20px;
			transform: translateY(-50%);
		}
	}

	.table-payments {
		.table__td:nth-child(1) {
			width: 26%;
		}

		.table__td:nth-child(2) {
			width: 16%;
		}

		.table__td:nth-child(3) {
			width: 21%;
		}

		.table__td:nth-child(4) {
			width: 18%;
		}

		.table__td:nth-child(5) {
			width: 19%;
		}
	}

	.content-middle-inner_2 {
		width: calc(100% - 18.3rem)
	}

	.table-satelite {

		.table__td:nth-child(even),
		.table__td:nth-child(odd) {
			width: 100%
		}
	}
}

@media screen and (min-width: 1200px) {
	.card-items {
		column-gap: 20px;
		columns: 2
	}

	.card-item {
		break-inside: avoid;
	}
}

@media screen and (min-width: 1301px) {
	.main-slider .slick-dots {
		left: calc(50vw - 600px);
	}
}