/* reset */
/* main variables */
/* reset */
/* main variables */
body {
  margin: 0rem;
  padding: 0rem;
  font-family: "HalvarEng", arial, sans-serif;
  background: #070707;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  position: relative;
  scroll-behavior: smooth;
  font-size: 1.4rem !important;
  line-height: 1.2;
}

/*MAIN*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
main {
  display: block;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-width: 0rem;
  padding: 0rem;
  margin: 0rem;
}

html {
  font-size: 10px !important;
  height: 100%;
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none;
}

input,
textarea,
select,
button {
  font-family: "HalvarEng", arial, sans-serif;
}

input,
textarea {
  color: #383A3C;
  font-family: "HalvarEng", arial, sans-serif;
  outline: none;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-appearance: none;
}

input[type=button],
input[type=submit],
button {
  cursor: pointer;
}

td {
  margin: 0rem;
  padding: 0rem;
}

form {
  padding: 0rem;
  margin: 0rem;
}

a {
  color: #fff;
  -webkit-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  text-decoration: none;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a:hover {
  color: #AF1515;
  text-decoration: none;
}

a,
span,
div,
button {
  outline: none !important;
}

input[type=submit],
input[type=button],
button {
  -webkit-appearance: none;
  outline: none;
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  outline: none;
}

*:before,
*:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

strong {
  font-weight: 700;
}

.clearfix:after,
.wrapper:after,
.row:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

img {
  max-width: 100%;
}

@font-face {
  font-family: "HalvarEng";
  src: url("../fonts/HalvarEng-Rg/HalvarEng-Rg.woff2") format("woff2"), url("../fonts/HalvarEng-Rg/HalvarEng-Rg.woff") format("woff"), url("../fonts/HalvarEng-Rg/HalvarEng-Rg.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "HalvarEng";
  src: url("../fonts/HalvarEng-Lt/HalvarEng-Lt.woff2") format("woff2"), url("../fonts/HalvarEng-Lt/HalvarEng-Lt.woff") format("woff"), url("../fonts/HalvarEng-Lt/HalvarEng-Lt.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "HalvarEng";
  src: url("../fonts/HalvarEng-Md/HalvarEng-Md.woff2") format("woff2"), url("../fonts/HalvarEng-Md/HalvarEng-Md.woff") format("woff"), url("../fonts/HalvarEng-Md/HalvarEng-Md.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "HalvarEng";
  src: url("../fonts/HalvarEng-Bd/HalvarEng-Bd.woff2") format("woff2"), url("../fonts/HalvarEng-Bd/HalvarEng-Bd.woff") format("woff"), url("../fonts/HalvarEng-Bd/HalvarEng-Bd.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "HalvarEng";
  src: url("../fonts/HalvarEng-RgSlanted/HalvarEng-RgSlanted.woff2") format("woff2"), url("../fonts/HalvarEng-RgSlanted/HalvarEng-RgSlanted.woff") format("woff"), url("../fonts/HalvarEng-RgSlanted/HalvarEng-RgSlanted.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Halvar Breitschrift";
  src: url("../fonts/HalvarBreit-Md/HalvarBreit-Md.woff2") format("woff2"), url("../fonts/HalvarBreit-Md/HalvarBreit-Md.woff") format("woff"), url("../fonts/HalvarBreit-Md/HalvarBreit-Md.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "icomoon";
  src: url("../fonts/icomoon/icomoon/fonts/icomoon.eot");
  src: url("../fonts/icomoon/icomoon/fonts/icomoon.eot?#iefix") format("embedded-opentype"), url("../fonts/icomoon/icomoon/fonts/icomoon.woff") format("woff"), url("../fonts/icomoon/icomoon/fonts/icomoon.svg#icomoon") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
[class^=icon-],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.main-wrapper {
  padding: 0;
  min-width: 320px;
  width: 100%;
  position: relative;
  overflow: hidden;
  min-height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out;
}

.wrapper {
  min-width: 320px;
  max-width: 1300px;
  padding-right: 5rem;
  padding-left: 5rem;
  margin: 0 auto;
  position: relative;
  width: 100%;
}

.slider-wrapper {
  padding-left: calc(50% - 63.9rem);
}

/* titles */
p {
  padding: 1.2rem 0;
}

h2 {
  font-weight: 400;
}

h1 {
  margin-bottom: 2.5rem;
}

.h1 {
  font-weight: 700;
  font-size: 3.6rem;
  line-height: 4.4rem;
  text-transform: uppercase;
  color: #365370;
  margin-bottom: 4.1rem;
  position: relative;
  z-index: 1;
}

.h2,
.h2-decor {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 1.1rem;
  text-transform: uppercase;
}

.h2-decor {
  display: inline-block;
  padding: 1rem 2rem;
  margin: 0;
  background: linear-gradient(90deg, #AF1515 0%, rgba(107, 17, 17, 0) 97.56%);
}

.section-subtitle {
  font-family: "Montserrat", arial, sans-serif;
  font-size: 1.7rem;
  line-height: 2.1rem;
  color: #93999E;
  margin-bottom: 1.5rem;
}

/* text position */
.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.nowrap {
  white-space: nowrap !important;
}

.center {
  text-align: center;
}

.loaded .main-wrapper {
  opacity: 0;
}

/*
================================================================================
|                                     CONTENT                                 |
================================================================================
*/
.content {
  min-width: 320px;
  text-align: left;
  width: 100%;
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding-top: 11.1rem;
  position: relative;
  z-index: 4;
}

.btn {
  background: #AF1515;
  border-radius: 0.8rem;
  border: 1px solid #AF1515;
  font-family: "Halvar Breitschrift", arial, sans-serif;
  cursor: pointer;
  height: 4.4rem;
  display: inline-block;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 4.4rem;
  text-align: center;
  padding: 0 1.5rem;
  color: #fff;
  -webkit-transition: background 0.5s ease, border-color 0.5s ease;
  -o-transition: background 0.5s ease, border-color 0.5s ease;
  transition: background 0.5s ease, border-color 0.5s ease, color 0.5s ease;
}

.btn-2 {
  color: #AF1515;
  border-color: #fff;
  background: #fff;
}

.btn:hover {
  background: #6B1111;
  border-color: #AF1515;
  color: #fff;
}

.btn-2:hover {
  background: #333;
  border-color: #D4D4D4;
  color: #fff;
}

.btn-3 {
  background: #0A0A0A;
  color: #fff;
  border: 1px solid #FFFFFF;
}

.form-control {
  color: rgba(212, 212, 212, 0.5);
  height: 54px;
  padding-left: 2.6rem;
  padding-right: 2.6rem;
  font-size: 18px;
  font-family: "HalvarEng", arial, sans-serif;
  width: 100%;
  background: #0F0F0F;
  border: 1px solid #333;
  border-radius: 8px;
  transition: border-color 0.3s ease-in;
}

.form-control:focus {
  border-color: #D4D4D4;
}

.form-control_md {
  height: 4.6rem;
}

.form-control_md:focus {
  border-color: #fff;
}

.form-control.error {
  border-color: #AF1515;
}

::-webkit-input-placeholder {
  color: rgba(212, 212, 212, 0.4);
}

::-moz-placeholder {
  color: rgba(212, 212, 212, 0.4);
}

:-moz-placeholder {
  color: rgba(212, 212, 212, 0.4);
}

:-ms-input-placeholder {
  color: rgba(212, 212, 212, 0.4);
}

label.error {
  color: #AF1515;
  font-size: 14px;
  line-height: 16px;
  margin: 0 1.5rem 0 0;
  display: block;
}

.checkbox-element label.error {
  position: absolute;
  left: 0;
  top: -2.7rem;
  margin: 0;
  white-space: nowrap;
}

.container label.error {
  margin: 0;
  white-space: nowrap;
}

.box-field {
  margin-bottom: 20px;
}

.box-field__label {
  font-size: 18px;
  margin-bottom: 9px;
}

.box-field_checkbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.web .box-field_checkbox a:hover {
  opacity: 1;
}

.scrollDisabled {
  overflow: hidden;
  position: fixed !important;
  margin-top: 0;
  width: 100%;
  z-index: 0;
}

.scrollDisabled.web,
.scrollDisabled.web.compensate-for-scrollbar {
  padding-right: 1.7rem;
}

.scrollDisabled .main-wrapper {
  overflow: visible;
}

.avatar {
  display: inline-block;
  width: 10.9rem;
  height: 10.9rem;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
}

.tabs-wrapper {
  position: relative;
}

.tab {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  opacity: 0;
  pointer-events: none;
  -webkit-transition: all 0.1s ease;
  -o-transition: all 0.1s ease;
  transition: all 0.1s ease;
  z-index: -1;
}

.tab.active {
  position: relative;
  opacity: 1;
  z-index: 1;
  pointer-events: auto;
  -webkit-transition: all 0.8s ease;
  -o-transition: all 0.8s ease;
  transition: all 0.8s ease;
}

.checkbox-element {
  cursor: pointer;
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.checkbox-element input {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  display: inline-block;
  margin-top: 0;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.checkbox-element input:checked ~ .check-label .check:after {
  opacity: 1;
}

.checkbox-element_2 .check:after {
  width: 10px;
  height: 10px;
  background: #28D88D;
  -webkit-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);
  border-radius: 3px;
}

.checkbox-element_2 .check-text {
  font-size: 1.4rem;
  line-height: 1.7rem;
  margin-left: 2.1rem;
}

.check {
  position: relative;
  display: block;
  width: 2.2rem;
  height: 2.2rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 0.4rem;
  position: relative;
  left: 0;
  top: -2px;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: #FFFFFF;
  -webkit-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);
  border-radius: 0.5rem;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.check:after {
  content: "";
  opacity: 0;
  -webkit-transition: opacity 0.15s ease-in;
  -o-transition: opacity 0.15s ease-in;
  transition: opacity 0.15s ease-in;
  line-height: 1;
}

.check-label {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.check-text {
  margin-left: 14px;
  font-size: 18px;
  line-height: 21px;
  color: rgba(56, 58, 60, 0.7);
}

.catalog-item {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.catalog-item__icon {
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 0.4rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.catalog-item__text {
  font-size: 1.5rem;
  line-height: 1.8rem;
  color: #383A3C;
  display: inline-block;
  margin-left: 1.2rem;
  -webkit-transition: color 0.3s ease;
  -o-transition: color 0.3s ease;
  transition: color 0.3s ease;
}

.catalog-item.current .catalog-item__text {
  color: #28D88D;
}

.web .catalog-item:hover .catalog-item__text {
  color: #28D88D;
}

.hide {
  display: none;
}

.box-field__input {
  position: relative;
}

.label-placeholder {
  position: absolute;
  left: 16px;
  top: 17px;
  pointer-events: none;
  color: #D4D4D4;
  opacity: 0.5;
  font-size: 18px;
  line-height: 21px;
  transition: all 0.5s ease;
}

.box-field__input_payment .form-control {
  padding-left: 60px;
  padding-right: 109px;
}

.box-field__input-icon {
  position: relative;
}
.box-field__input-icon .label-placeholder {
  left: 55px;
}

.filled .t-inp {
  padding-right: 75px;
}
.filled .label-placeholder {
  left: 100%;
  transform: translateX(-100%);
  margin-left: -20px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 14px;
}
.filled .box-field__input-icon .label-placeholder {
  left: 100%;
}

.box-field__input-icon .form-control {
  padding-left: 53px;
}

.box-field__icon {
  position: absolute;
  left: 15px;
  top: 16px;
  color: #333;
  font-size: 24px;
  z-index: 2;
}

.note-popup-link {
  margin: -18px 0 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.password-link {
  margin-left: auto;
  font-size: 14px;
  line-height: 16px;
  color: #D4D4D4;
  border-bottom: 1px solid rgba(255, 255, 255, 0.8);
  flex-shrink: 0;
}

.password-link:hover {
  border-bottom-color: transparent;
}

@media screen and (min-width: 768px) {
  .mob-show,
.bars-mob_cross {
    display: none;
  }
}
@media screen and (min-width: 1024px) {
  .tablet-show {
    display: none !important;
  }
}
@media screen and (min-width: 1024px) and (max-height: 700px) {
  .arrow-scroll {
    bottom: 0;
  }
}
@media screen and (max-width: 1365px) {
  .wrapper {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
}
@media screen and (max-width: 1023px) {
  .tablet-hide {
    display: none !important;
  }
}
@media screen and (max-width: 767px) {
  .mob-hide {
    display: none;
  }

  .wrapper {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .check {
    width: 1.8rem;
    height: 1.8rem;
  }

  .checkbox-element_2 .check:after {
    width: 0.8rem;
    height: 0.8rem;
  }

  .h2-decor {
    padding: 4px 10px;
    font-size: 16px;
    line-height: 18px;
  }
}
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.slick-track:before,
.slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  min-height: 1px;
  display: none;
}

[dir=rtl] .slick-slide {
  float: right;
}

.slick-slide img {
  vertical-align: top;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow {
  display: block;
  border: 0;
  top: 50%;
  left: -8.7rem;
  margin-top: -6.1rem;
  padding-right: 0.2rem;
  position: absolute;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 2;
  color: #fff;
  cursor: pointer;
  background: #414141;
  -webkit-box-shadow: 0px 0.6rem 0.4rem rgba(0, 0, 0, 0.12);
  box-shadow: 0px 0.6rem 0.4rem rgba(0, 0, 0, 0.12);
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
  height: 5rem;
  width: 5rem;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: background 0.4s ease;
  -o-transition: background 0.4s ease;
  transition: background 0.4s ease;
}

.slick-arrow svg {
  fill: #fff;
}

.slick-next {
  padding-left: 0;
  padding-right: 0.2rem;
  right: -8.4rem;
  left: auto;
}

.web .slick-arrow:hover {
  border-color: #E14D4D;
  background: #E14D4D;
  color: #fff;
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-dots {
  margin-top: 2.3rem;
  text-align: center;
  list-style: none;
  width: 100%;
}

.slick-dots li {
  display: inline-block;
  margin-right: 0.2rem;
  position: relative;
  padding: 3px;
  cursor: pointer;
  border: 0;
}

.slick-dots li:last-child {
  margin-right: 0;
}

.slick-dots button {
  text-indent: -99999px;
  font-size: 0;
  border: 0;
  height: 3px;
  width: 6px;
  background: #fff;
  height: 6px;
  padding: 0;
  vertical-align: top;
  -webkit-border-radius: 0;
  border-radius: 50%;
  border: 50%;
}

.slick-dots .slick-active button {
  background: #AF1515;
}

.slick-disabled,
.slick-disabled:hover {
  opacity: 0.3;
  cursor: default;
  pointer-events: none;
}

.slick-disabled:before,
.slick-disabled:hover:before {
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
}

.header {
  z-index: 10;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  z-index: 100;
  color: #000;
  -webkit-transition: -webkit-box-shadow 0.3s ease;
  transition: -webkit-box-shadow 0.3s ease;
  -o-transition: box-shadow 0.3s ease;
  transition: box-shadow 0.3s ease;
  transition: box-shadow 0.3s ease, -webkit-box-shadow 0.3s ease;
}

.header .wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.header .wrapper:after {
  display: none;
}

.header-navs {
  display: flex;
  align-items: center;
}

.logo {
  display: inline-block;
  width: 21.2rem;
  vertical-align: top;
}

.bars-mob {
  margin-right: 10px;
  width: 2rem;
  height: 2rem;
  display: none;
  padding: 0.5rem;
  position: relative;
  cursor: pointer;
  margin-top: -8px;
}

.bars-mob .hamburger {
  position: absolute;
  height: 2rem;
  width: 2rem;
  left: 0;
}

.bars-mob .hamburger span {
  display: block;
  width: 100%;
  height: 0.2rem;
  position: relative;
  background: #fff;
  top: 0;
  left: 0;
  margin: 0 0 0.5rem;
  -webkit-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

.bars-mob .hamburger span:nth-child(1) {
  -webkit-transition-delay: 0.5s;
  -o-transition-delay: 0.5s;
  transition-delay: 0.5s;
}

.bars-mob .hamburger span:nth-child(2) {
  -webkit-transition-delay: 0.625s;
  -o-transition-delay: 0.625s;
  transition-delay: 0.625s;
}

.bars-mob .hamburger span:nth-child(3) {
  -webkit-transition-delay: 0.75s;
  -o-transition-delay: 0.75s;
  transition-delay: 0.75s;
}

.bars-mob .cross {
  position: absolute;
  height: 2rem;
  width: 2rem;
  margin: 0 0 0 2px;
  -webkit-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.bars-mob .cross span {
  display: block;
  background: #fff;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

.bars-mob .cross span:nth-child(1) {
  height: 100%;
  width: 0.2rem;
  position: absolute;
  -webkit-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
}

.bars-mob .cross span:nth-child(2) {
  width: 100%;
  height: 0.2rem;
  position: absolute;
  -webkit-transition-delay: 0.25s;
  -o-transition-delay: 0.25s;
  transition-delay: 0.25s;
}

.bars-mob.active .hamburger span {
  width: 0%;
}

.bars-mob.active .hamburger span:nth-child(1) {
  -webkit-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
}

.bars-mob.active .hamburger span:nth-child(2) {
  -webkit-transition-delay: 0.125s;
  -o-transition-delay: 0.125s;
  transition-delay: 0.125s;
}

.bars-mob.active .hamburger span:nth-child(3) {
  -webkit-transition-delay: 0.25s;
  -o-transition-delay: 0.25s;
  transition-delay: 0.25s;
}

.bars-mob.active .cross span:nth-child(1) {
  height: 100%;
  -webkit-transition-delay: 0.625s;
  -o-transition-delay: 0.625s;
  transition-delay: 0.625s;
}

.bars-mob.active .cross span:nth-child(2) {
  width: 100%;
  -webkit-transition-delay: 0.375s;
  -o-transition-delay: 0.375s;
  transition-delay: 0.375s;
}

.header-main .header-wrapper {
  padding-top: 1.6rem;
  padding-bottom: 1rem;
}

.btn_login {
  margin: 0 0 0 1rem;
  height: 3.6rem;
  line-height: 3.4rem;
  font-size: 1.8rem;
  font-family: HalvarEng, arial, sans-serif;
  font-weight: 400;
}

.btn_login.btn-2 {
  color: #0A0A0A;
}

.btn_login.btn-2:hover {
  color: #fff;
}

.header-menu {
  border-top: 1px solid rgba(212, 212, 212, 0.1);
}

.main-menu {
  width: 100%;
}
.main-menu ul {
  margin: 0 -1rem;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.main-menu li {
  margin: 0 0.5rem;
  display: inline-flex;
  align-items: center;
}
.main-menu a {
  font-size: 18px;
  line-height: 21px;
  color: #fff;
  min-height: 4.8rem;
  padding: 0 15px;
  display: inline-flex;
  align-items: center;
  text-transform: uppercase;
  transition: color 0.3s ease;
}
.main-menu li svg {
  fill: #AF1515;
  margin-right: 1rem;
  transition: fill 0.3s ease;
}
.main-menu .current-menu-item a {
  background: linear-gradient(180deg, rgba(175, 21, 21, 0) 0%, #AF1515 100%);
}
.main-menu .current-menu-item a svg {
  fill: #fff;
}
.main-menu a:hover {
  color: #AF1515;
}
.main-menu a:hover svg {
  fill: #fff;
}
.main-menu .current-menu-item a,
.main-menu .current-menu-item a:hover {
  color: #fff;
}
.main-menu .current-menu-item a svg,
.main-menu .current-menu-item a:hover svg {
  fill: #fff;
}

.dropdown-header {
  margin-left: 20px;
}

.dropdown {
  position: relative;
}

.login-ic {
  width: 46px;
  height: 36px;
  color: #fff;
  font-size: 20px;
  background: #0A0A0A;
  border: 1px solid #504E4F;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: color 0.5s ease, border-color 0.5s ease, background 0.5s ease;
}

.login-ic:hover {
  background: #D4D4D4;
  color: #0A0A0A;
  border: 1px solid #333333;
}

.dropdown-trigger {
  cursor: pointer;
}

.dropdown-body {
  display: none;
  font-size: 14px;
  line-height: 20px;
  border-radius: 10px;
  position: absolute;
  right: -10px;
  top: 100%;
  min-width: 100%;
  width: max-content;
  z-index: 100;
  background: #0A0A0A;
  border: 1px solid #504E4F;
  box-shadow: 0px 20px 25px rgba(0, 0, 0, 0.1), 0px 10px 10px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
}

[data-open=true] .dropdown-body {
  display: block;
}

.header-menu-profile {
  list-style: none;
}

.header-menu-profile a {
  font-size: 18px;
  line-height: 21px;
  padding: 14px 20px;
  display: flex;
  align-items: center;
}

.header-menu-profile a i {
  margin-right: 10px;
  color: #333;
  font-size: 20px;
}

.header-menu-profile li + li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.header-btns {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.header-balance {
  margin-right: 10px;
  color: #333;
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 19px;
}

.header-balance + .header-balance {
  position: relative;
  padding: 0 0 0 14px;
  margin: 0 0 0 3px;
}

.header-balance + .header-balance:before {
  content: "";
  display: block;
  position: absolute;
  width: 1px;
  height: 16px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background: #333;
}

.header-balance__text2 {
  margin-left: 8px;
}

.header-balance__text2 i {
  margin-left: 5px;
  vertical-align: middle;
}

.header-balance__text {
  color: #fff;
}

.color-red {
  color: #AF1515;
}

.color-green {
  color: #50BC1D;
}

.header-balance__ic {
  color: #333;
  margin-right: 10px;
  font-size: 25px;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 1023px) {
  .logo {
    width: 17.2rem;
  }
}
@media screen and (max-width: 767px) {
  .header-main .header-wrapper {
    padding-top: 1.2rem;
    padding-bottom: 1rem;
  }

  .logo {
    width: 13rem;
  }

  .btn_login {
    height: 3.2rem;
    line-height: 3rem;
    padding: 0 0.7rem;
    min-width: 5.6rem;
  }

  .main-menu li {
    margin: 0;
  }
  .main-menu li svg {
    width: 1.6rem;
    height: 1.6rem;
    margin-right: 0.4rem;
  }
  .main-menu a {
    padding: 0 0.5rem;
    font-size: 1.5rem;
    line-height: 1.7rem;
    min-height: 4.6rem;
  }

  .bars-mob {
    display: block;
  }

  .open-header .header {
    z-index: 0;
  }

  .bars-mob_cross {
    position: absolute;
    z-index: 1;
    top: 19px;
    left: 9px;
  }

  .overlay {
    position: fixed;
    pointer-events: none;
    transition: all 0.5s ease;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: #0A0A0A;
    z-index: 100;
    opacity: 0;
  }

  .overlay.shown {
    pointer-events: auto;
    opacity: 0.8;
  }

  .header-balance {
    font-size: 14px;
    line-height: 16px;
  }

  .menu-aside .aside-menu ul {
    display: block;
    width: 100%;
  }

  .menu-aside .aside-menu ul a {
    font-size: 16px;
    line-height: 19px;
    padding: 1rem 1.2rem 1rem 1rem;
    display: flex;
    align-items: center;
    flex-direction: row;
  }

  .aside-menu ul svg {
    margin: 0 10px 0 0;
  }

  .header-menu-profile {
    margin-top: 3px;
  }

  .header-menu-profile a {
    font-size: 18px;
    line-height: 21px;
    padding: 10px;
  }

  .header-balance__ic {
    display: none;
  }

  .header-btns {
    margin-left: auto;
    display: block;
    text-align: right;
  }

  .header-balance {
    margin: 0;
    justify-content: flex-end;
  }

  .header-balance + .header-balance {
    padding: 0;
    margin: 0;
  }
}
@media screen and (max-width: 374px) {
  .header-balance {
    font-size: 13px;
    line-height: 15px;
  }

  .logo {
    width: 10rem;
  }

  .main-menu a {
    font-size: 13px;
    line-height: 16px;
  }
}
.section-main {
  padding-bottom: 93px;
}

.main-block {
  display: flex;
  flex-wrap: wrap;
}

.main-slider {
  margin-bottom: 2.4rem;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

.main-slider__item {
  position: relative;
}

.main-slider__item-bg {
  background-position: 0 50%;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 0;
  position: absolute;
  left: 29.5%;
  top: 0;
  right: 0;
  bottom: 0;
}

.main-slider__item .wrapper {
  display: flex;
  min-height: 39.2rem;
  z-index: 1;
}

.slick-initialized .main-slider__item {
  display: flex;
}

.main-slider__item:hover {
  color: #fff;
}

.main-slider__heading {
  padding: 5rem 3rem 5rem 0;
  width: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.main-slider__img {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-direction: column;
}

.main-slider__img img {
  vertical-align: top;
}

.main-slider__title {
  font-weight: 700;
  font-size: 4.6rem;
  line-height: 5.3rem;
  text-transform: uppercase;
  margin-bottom: 1rem;
}

.main-slider__text {
  font-size: 20px;
  line-height: 23px;
}

.main-slider__btn {
  margin-top: 3rem;
}

.main-slider__note {
  margin-top: 2.9rem;
  font-size: 14px;
  line-height: 16px;
}

.main-slider .slick-dots {
  margin: 0;
  position: absolute;
  left: 5rem;
  bottom: 1.8rem;
  z-index: 10;
  width: auto;
}

.main-block-content {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.aside-navs {
  width: 18.3rem;
}

.content-middle {
  padding: 0 2rem 0 0;
  width: calc(100% - 18.3rem);
}

.content-middle__middle {
  padding: 0 0 0 2rem;
  width: calc(100% - 18.3rem);
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.card-items {
  margin: 0 -1rem;
}

.card-item {
  margin: 0 1rem 2rem;
  background: #0F0F0F;
}

.card-item__head {
  position: relative;
  padding: 12px 12px 12px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(51, 51, 51, 0.2);
}

.card-item__head:before {
  content: "";
  display: block;
  background: #AF1515;
  width: 0.3rem;
  height: 2rem;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.card-item__body {
  padding: 8px 9px 14px 12px;
}

.card-item__title {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  margin-right: 1.5rem;
}

.card-item__date {
  font-size: 16px;
  line-height: 19px;
  color: #D4D4D4;
}

.card-item__table {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.card-item__row {
  display: table-row;
}

.card-item__row + .card-item__row .card-item__col {
  padding-top: 15px;
  border-top: 1px solid rgba(51, 51, 51, 0.1);
}

.card-item__row-head + .card-item__row .card-item__col {
  padding-top: 0;
  border-top-width: 0;
}

.card-item__col {
  padding: 0 0.3rem 11px;
  width: 3.6rem;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.card-item__col:first-child {
  text-align: left;
  width: calc(100% - 21.6rem);
}

.card-item__row-head .card-item__col {
  color: #D4D4D4;
  opacity: 0.5;
}

.card-item__name {
  font-size: 1.6rem;
  line-height: 1.16;
}

.card-item__item {
  background: #0A0A0A;
  border: 1px solid rgba(80, 78, 79, 0.3);
  height: 4.4rem;
  width: 3rem;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-item__item i {
  color: #333;
  font-size: 16px;
}

.card-item__item:hover {
  background: #D4D4D4;
  color: #0A0A0A;
}

.card-item__item.disabled {
  pointer-events: none;
  border-color: transparent;
  background: transparent;
}

.aside-menu {
  background: #0F0F0F;
}

.aside-menu ul {
  list-style: none;
}

.aside-menu ul a {
  font-size: 16px;
  line-height: 19px;
  padding: 1rem 1.2rem 1rem 1rem;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  transition: color 0.5s ease;
}

.aside-menu ul svg {
  fill: #AF1515;
  margin-right: 10px;
}

.aside-menu ul .current-menu-item a,
.aside-menu ul a:hover {
  color: #AF1515;
}

.aside-menu ul .current-menu-item a {
  border-right: 3px solid #AF1515;
}

.aside-menu__count {
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  color: #504E4F;
}

.aside-menu_2 ul a {
  justify-content: space-between;
}

.aside-menu:last-child ul li:last-child a {
  border-bottom-width: 0;
}

.aside-menu__heading {
  font-size: 14px;
  line-height: 16px;
  font-style: italic;
  color: #504E4F;
  padding: 10px 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.promo {
  margin-top: 20px;
  position: relative;
}

.tab-nav {
  display: flex;
}

.tab-nav__link {
  background: rgba(51, 51, 51, 0.2);
  padding: 10px 0.5rem;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  flex: 1 1 100%;
  text-align: center;
  cursor: pointer;
  border-bottom: 0.3rem solid rgba(51, 51, 51, 0.2);
  transition: border-color 0.5s ease, background 0.5s ease;
}

.tab-nav__link.active {
  background: #333;
  border-color: #AF1515;
}

.tab-nav__link:hover {
  background: #333;
}

.tabs-aside {
  background: #0F0F0F;
}

.aside-tab {
  padding: 13px 1rem 29px;
}

.note-info {
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
}

.note-info svg {
  margin-right: 1rem;
  flex-shrink: 0;
  font-size: 16px;
  line-height: 19px;
}

.note-text {
  margin: 16px 6px 0 0;
  font-size: 14px;
  line-height: 16px;
  color: #504E4F;
}

.section-heading {
  margin-bottom: 1.8rem;
  background: #0F0F0F;
  width: 100%;
}

.menu-aside {
  background: #0F0F0F;
}
.menu-aside__head {
  font-size: 16px;
  line-height: 19px;
}
.menu-aside__title {
  background: rgba(56, 58, 60, 0.2);
  padding: 10px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.menu-aside__title i {
  color: #504E4F;
  font-size: 10px;
}
.menu-aside__body {
  display: none;
}
.menu-aside ul a {
  color: #FFF;
}
.menu-aside ul a.disabled {
  pointer-events: none;
  color: #333;
}
.menu-aside ul a.disabled svg {
  fill: #333;
}
.menu-aside ul a:hover {
  color: #AF1515;
}
.menu-aside .aside-menu ul svg {
  fill: #504E4F;
}
.menu-aside.active .menu-aside__title i {
  transform: rotateX(180deg);
}

.web .menu-aside__title:hover .menu-aside__head {
  color: #AF1515;
}

.aside-menu ul .current-menu-item svg {
  fill: #AF1515;
}

.content-middle-inner {
  width: calc(100% - 36.6rem);
  padding: 0 2rem;
}

.section-inner {
  position: relative;
  background: #0A0A0A;
  padding: 10px 0 130px;
}

.section-inner__bg {
  position: relative;
  opacity: 0.08;
  filter: grayscale(1) brightness(83%) contrast(141%);
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  z-index: 0;
  pointer-events: none;
}

.section-inner .main-block {
  margin-top: 20px;
}

.section-inner .main-block {
  position: relative;
  z-index: 1;
}

.table {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.table__row {
  display: table-row;
}
.table__row.js-fancybox {
  cursor: pointer;
}

.table__td {
  padding: 12px 7px 12px 15px;
  display: table-cell;
}
.table__td:nth-child(1) {
  width: 15%;
}
.table__td:nth-child(2) {
  width: 21%;
}
.table__td:nth-child(3) {
  width: 9%;
}
.table__td:nth-child(4) {
  width: 9%;
}
.table__td:nth-child(5) {
  width: 11%;
}
.table__td:nth-child(6) {
  width: 11%;
}
.table__td:nth-child(7) {
  width: 12%;
}

.table__row_head .table__td {
  background: rgba(51, 51, 51, 0.3);
  font-weight: 500;
  font-size: 18px;
}

.table-wins {
  font-size: 16px;
  line-height: 19px;
  position: relative;
}

.table-wins-wrap {
  overflow: auto;
}

.table-clone {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  pointer-events: none;
}

.footer {
  order: 3;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track {
  background: #0A0A0A;
}

::-webkit-scrollbar-thumb {
  background: #333;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

body.web::-webkit-scrollbar {
  width: 10px;
}

body.ios::-webkit-scrollbar {
  width: 0;
}

.window-open {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  overflow: hidden;
  transform: translate(0, -200%);
}

.heading-count {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #504E4F;
}

.catalog-games-wrapper + .catalog-games-wrapper {
  margin-top: 70px;
}

.catalog-games {
  margin: 0 -10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.games-item {
  margin: 0 10px 20px;
  width: calc(16.667% - 20px);
}
.games-item__img {
  position: relative;
  margin-bottom: 14px;
  overflow: hidden;
  vertical-align: top;
}
.games-item__img:before {
  content: "";
  display: block;
  padding-top: 75%;
}
.games-item__img img {
  height: 100%;
  width: 100%;
  position: absolute;
  vertical-align: top;
  top: 0;
  left: 0;
  transition: transform 0.5s ease;
}
.games-item:hover .games-item__img img {
  transform: scale(1.05);
}
.games-item__title {
  font-size: 20px;
  line-height: 23px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.more-btn {
  margin-top: 27px;
  text-align: center;
}

.note-empty {
  margin-top: 15px;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
}

.article {
  font-size: 14px;
  line-height: 18px;
  color: #504E4F;
  background: #0F0F0F;
}
.article a {
  color: #AF1515;
}
.article a:hover {
  color: #fff;
}
.article__info {
  padding: 29px 0 20px;
  display: none;
}
.article__title {
  padding: 5px 0;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.article__title i {
  font-size: 24px;
  flex-shrink: 0;
  margin-left: 15px;
  color: #504E4F;
  display: inline-block;
  vertical-align: top;
  transition: transform 0.3s ease, color 0.3s ease;
}
.article__item {
  padding: 8px 30px 7px;
}
.article__item.active .article__title i {
  color: #fff;
  transform: rotateX(180deg);
}
.article p {
  padding: 5px 0;
}

.web .article__title:hover i {
  color: #AF1515;
}

.content-middle-inner_satelite {
  width: 100%;
  padding: 0;
  max-height: calc(100vh - 130px);
  overflow: auto;
  background: #0F0F0F;
}
.content-middle-inner_satelite .table__row_head .table__td:nth-child(1):before {
  width: 0;
}
.content-middle-inner_satelite::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
.content-middle-inner_satelite::-webkit-scrollbar-track {
  background: inherit;
}
.content-middle-inner_satelite::-webkit-scrollbar-thumb {
  background: #fff;
}
.content-middle-inner_satelite::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.btn_login-inner {
  white-space: nowrap;
}

.img-table-logo {
  max-height: 18px;
  vertical-align: top;
}

.settings-form {
  padding: 10px 30px 20px;
  background: #0F0F0F;
}
.settings-form form {
  max-width: 361px;
}

.menu-payment {
  list-style: none;
}
.menu-payment li {
  padding: 7px 16px 7px 20px;
  font-size: 18px;
  line-height: 21px;
  cursor: pointer;
  border-bottom: 1px solid rgba(212, 212, 212, 0.1);
  min-height: 47px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.menu-payment li:hover, .menu-payment li.current {
  color: #AF1515;
}

.label-payment {
  position: absolute;
  left: 16px;
  top: 17px;
  pointer-events: none;
  color: #D4D4D4;
  opacity: 0.5;
  font-size: 18px;
  line-height: 21px;
  transition: all 0.5s ease;
}

.dropdown-select {
  position: absolute;
  top: 0;
  right: 0;
  padding-right: 16px;
}
.dropdown-select .dropdown-trigger {
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.dropdown-select .dropdown-trigger img {
  max-height: 23px;
  max-width: 93px;
}
.dropdown-select .dropdown-body {
  right: 0;
  width: calc(100vw - 32px);
  max-width: 361px;
}

@media screen and (max-width: 1365px) {
  .main-slider__item .wrapper {
    min-height: 0;
  }

  .main-slider__item {
    background-position: 180% 50%;
  }

  .main-slider__heading {
    padding: 4rem 3rem 4rem 0;
  }

  .main-slider .slick-dots {
    bottom: 0.8rem;
  }
}
@media screen and (max-width: 1280px) {
  .main-slider__title {
    font-weight: 700;
    font-size: 4rem;
    line-height: 4.8rem;
  }

  .main-slider__text {
    font-size: 18px;
    line-height: 21px;
  }

  .card-item__name {
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 1279px) {
  .table-wins {
    font-size: 14px;
    line-height: 17px;
  }

  .games-item {
    width: calc(20% - 20px);
  }

  .section-inner {
    padding: 10px 0 87px;
  }
}
@media screen and (max-width: 1023px) {
  .content-middle__middle .aside-navs_right {
    order: 2;
  }

  .content-middle {
    margin-top: 2rem;
    padding-right: 0;
    width: 100%;
    order: 3;
  }

  .content-middle__middle .section-heading {
    order: 1;
  }

  .content-middle-inner {
    width: calc(100% - 18.3rem);
    padding-right: 0;
  }

  .table__td {
    padding: 12px 7px;
  }

  .games-item {
    width: calc(25% - 20px);
  }

  .content-middle-inner_satelite {
    width: 100%;
  }

  .aside-navs_right {
    width: 100%;
    max-width: 450px;
  }

  .promo {
    max-width: 450px;
  }

  .promo a {
    display: block;
  }

  .section-inner {
    padding: 10px 0 77px;
  }
}
@media screen and (max-width: 1023px) and (min-width: 768px) {
  .table-wins {
    font-size: 13px;
    line-height: 18px;
  }
  .table-wins .table__row_head .table__td {
    font-size: 14px;
  }
  .table-wins .table__td {
    padding: 12px 5px;
  }
}
@media screen and (max-width: 767px) {
  .section-inner {
    padding-bottom: 50px;
  }

  .mob-menu {
    margin: 0 5px;
    display: flex;
    overflow: auto;
  }

  .aside-menu ul {
    display: flex;
  }

  .aside-menu ul a {
    font-size: 12px;
    line-height: 12px;
    padding: 2px 5px 2px;
    text-align: center;
    flex-direction: column;
    align-items: center;
  }

  .aside-menu {
    display: flex;
  }

  .aside-menu__heading {
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    white-space: nowrap;
  }

  .aside-menu ul svg {
    margin: 0 0.2rem 0 0;
  }

  .aside-menu_main ul .current-menu-item a {
    border-right: 0;
  }

  .aside-menu_2 LI {
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
  }

  .aside-menu_2 ul a {
    flex: 1 1 100%;
    justify-content: space-between;
  }

  .main-slider {
    margin-bottom: 1rem;
  }

  .main-slider__title {
    font-size: 24px;
    line-height: 26px;
    margin-bottom: 6px;
  }

  .main-slider__heading {
    width: 100%;
    padding: 5px 0 20px 10px;
  }

  .main-slider__img {
    width: 51%;
    position: absolute;
    top: 31%;
    right: 15px;
    bottom: 0;
  }

  .main-slider__btn .btn {
    font-size: 14px;
    line-height: 2.5rem;
    height: 2.7rem;
  }

  .main-slider__text {
    font-size: 14px;
    line-height: 16px;
    max-width: 61%;
  }

  .main-slider__btn {
    margin-top: 1.3rem;
  }
  .main-slider__btn .btn {
    padding: 0 1rem;
  }

  .main-slider__note {
    margin-top: 1.2rem;
    font-size: 12px;
    line-height: 14px;
  }

  .main-slider .slick-dots {
    bottom: -0.2rem;
    left: 15px;
  }

  .content-middle__middle {
    width: 100%;
    padding-left: 0;
  }

  .promo {
    width: 100%;
  }

  .aside-navs {
    width: 100%;
  }

  .note-text {
    margin: 10px 6px 0 0;
  }

  .aside-tab {
    padding: 10px 1rem 16px;
  }

  .h2,
.h2-decor {
    font-size: 16px;
    line-height: 18px;
  }

  .h2-decor {
    padding: 5px 10px 4px;
  }

  .section-heading {
    margin-bottom: 1rem;
  }

  .card-items {
    margin: 0 -1rem 0 -2rem;
    padding: 1px 15px 2px;
    display: block;
  }
  .card-items .slick-list {
    overflow: visible;
  }

  .card-item__col {
    width: 3.5rem;
  }

  .card-item__col:first-child {
    width: calc(100% - 21rem);
  }

  .card-item {
    height: 100%;
    width: calc(100vw - 3.2rem);
    margin: 0 0.4rem 1rem;
    max-width: 34.3rem;
    flex-shrink: 0;
  }

  .section-inner__bg {
    background-attachment: scroll;
  }

  .aside-navs_inner {
    position: fixed;
    left: 0;
    transform: translateX(-100%);
    top: 0;
    padding-top: 53px;
    margin: 0;
    z-index: 110;
    width: 100%;
    bottom: 0;
    max-width: 32rem;
    overflow: auto;
    will-change: right;
    background: #0A0A0A;
    box-shadow: 0px 20px 25px rgba(0, 0, 0, 0.1), 0px 10px 10px rgba(0, 0, 0, 0.04);
    transition: transform 0.5s ease;
    bottom: 0;
  }

  .aside-navs_inner.active {
    transform: translateX(0);
  }

  .content-middle-inner {
    width: 100%;
    padding: 0;
  }

  .table-wins {
    font-size: 16px;
    line-height: 19px;
  }

  .table {
    display: flex;
  }

  .table__row_head {
    position: absolute;
    left: 0;
    right: 0;
    opacity: 0;
    pointer-events: none;
  }

  .table__row,
.table__td {
    display: block;
  }

  .table__row {
    border-left: 2px solid #070707;
  }

  .table__row_head .table__td {
    font-size: 16px;
    background: #1C1C1C;
  }

  .table-clone {
    opacity: 1;
    display: inline-flex;
    width: auto;
  }
  .table-clone .table__row {
    opacity: 0;
  }
  .table-clone .table__row_head {
    opacity: 1;
    position: relative;
    flex-shrink: 0;
  }
  .table-clone .table__row-empty {
    opacity: 1;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .table-clone_empty {
    width: 100%;
  }

  .table__td {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 43px;
    padding-top: 3px;
    padding-bottom: 3px;
  }

  .table-clone .table__row_head .table__td {
    border-bottom: 1px solid #070707;
    border-top: 1px solid #070707;
  }

  .games-item {
    width: calc(33% - 20px);
  }

  .table__td:nth-child(odd),
.table__td:nth-child(even) {
    width: 100%;
  }

  .section-main {
    padding-bottom: 35px;
  }

  .note-empty {
    display: inline-flex;
    max-width: 80px;
  }

  .aside-menu ul .current-menu-item a {
    border-width: 0;
  }

  .aside-menu_3 {
    white-space: nowrap;
    margin: -9px 0 4px;
    display: inline-block;
  }
  .aside-menu_3 ul a {
    padding: 5px 10px;
    font-size: 16px;
  }

  .article__item {
    padding: 0 14px 10px;
  }

  .article__item_main {
    padding-bottom: 21px;
  }

  .article__info {
    padding: 18px 0 10px;
  }

  .btn_login-inner {
    font-size: 13px;
    height: 24px;
    line-height: 22px;
    margin-left: -7px;
    padding: 0 13px;
    margin-right: 10px;
  }

  .content-middle-inner_satelite .table-clone {
    min-width: 103px;
  }

  .content-middle-inner_satelite {
    overflow: visible;
  }

  .table-wins-wrap {
    margin-right: -16px;
  }

  .settings-form {
    padding: 4px 16px 0;
  }

  .menu-aside .aside-menu ul svg {
    margin-right: 8px;
  }
}
@media screen and (max-width: 480px) {
  .games-item {
    width: calc(50% - 20px);
  }
}
@media screen and (max-width: 374px) {
  .settings-form {
    padding: 4px 0 0;
  }

  .box-field__input_payment .form-control {
    padding-left: 20px !important;
  }

  .label-payment {
    display: none;
  }
}
@media screen and (min-width: 768px) {
  .table__row_head .table__td:nth-child(1) {
    position: relative;
  }
  .table__row_head .table__td:nth-child(1):before {
    content: "";
    position: absolute;
    background: #AF1515;
    top: 50%;
    left: 0;
    width: 3px;
    height: 20px;
    transform: translateY(-50%);
  }

  .table-payments .table__td:nth-child(1) {
    width: 26%;
  }
  .table-payments .table__td:nth-child(2) {
    width: 16%;
  }
  .table-payments .table__td:nth-child(3) {
    width: 21%;
  }
  .table-payments .table__td:nth-child(4) {
    width: 18%;
  }
  .table-payments .table__td:nth-child(5) {
    width: 19%;
  }

  .content-middle-inner_2 {
    width: calc(100% - 18.3rem);
  }

  .table-satelite .table__td:nth-child(even),
.table-satelite .table__td:nth-child(odd) {
    width: 100%;
  }
}
@media screen and (min-width: 1200px) {
  .card-items {
    column-gap: 20px;
    columns: 2;
  }

  .card-item {
    break-inside: avoid;
  }
}
@media screen and (min-width: 1301px) {
  .main-slider .slick-dots {
    left: calc(50vw - 600px);
  }
}