@import '../utils/_mixins';
@import '../utils/_variables';



/* reset */
/* main variables */
body {
	margin: 0rem;
	padding: 0rem;
	font-family: "HalvarEng", arial, sans-serif;
	background: #070707;
	height: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #fff;
	position: relative;
	scroll-behavior: smooth;
	font-size: 1.4rem !important;
	line-height: 1.2;
}

/*MAIN*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
main {
	display: block;
}

blockquote,
q {
	quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
	content: '';
	content: none;
}

table {
	border-collapse: collapse;
	border-width: 0rem;
	padding: 0rem;
	margin: 0rem;
}

html {
	font-size: 10px !important;
	height: 100%;
	-webkit-text-size-adjust: none;
	-ms-text-size-adjust: none;
}

input,
textarea,
select,
button {
	font-family: "HalvarEng", arial, sans-serif;
}

input,
textarea {
	color: #383A3C;
	font-family: "HalvarEng", arial, sans-serif;
	outline: none;
	border-radius: 0;
	-moz-border-radius: 0;
	-webkit-border-radius: 0;
	-webkit-appearance: none;
}

input[type="button"],
input[type="submit"],
button {
	cursor: pointer;
}

td {
	margin: 0rem;
	padding: 0rem;
}

form {
	padding: 0rem;
	margin: 0rem;
}

a {
	color: #fff;
	-webkit-transition: all 0.3s ease-in;
	-o-transition: all 0.3s ease-in;
	transition: all 0.3s ease-in;
	text-decoration: none;
	outline: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a:hover {
	color: #AF1515;
	text-decoration: none;
}

a,
span,
div,
button {
	outline: none !important;
}

input[type=submit],
input[type=button],
button {
	-webkit-appearance: none;
	outline: none;
}

* {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	outline: none;
}

*:before,
*:after {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

strong {
	font-weight: 700;
}

.clearfix:after,
.wrapper:after,
.row:after {
	content: ".";
	display: block;
	height: 0;
	clear: both;
	visibility: hidden;
}

img {
	max-width: 100%;
}

