
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.slick-track:before,
.slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  vertical-align: top;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow {
  display: block;
  border: 0;
  top: 50%;
  left: -8.7rem;
  margin-top: -6.1rem;
  padding-right: 0.2rem;
  position: absolute;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 2;
  color: #fff;
  cursor: pointer;
  background: #414141;
  -webkit-box-shadow: 0px 0.6rem 0.4rem rgba(0, 0, 0, 0.12);
  box-shadow: 0px 0.6rem 0.4rem rgba(0, 0, 0, 0.12);
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
  height: 5rem;
  width: 5rem;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: background .4s ease;
  -o-transition: background .4s ease;
  transition: background .4s ease;
}

.slick-arrow svg {
  fill: #fff;
}

.slick-next {
  padding-left: 0;
  padding-right: 0.2rem;
  right: -8.4rem;
  left: auto;
}

.web .slick-arrow:hover {
  border-color: #E14D4D;
  background: #E14D4D;
  color: #fff;
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-dots {
  margin-top: 2.3rem;
  text-align: center;
  list-style: none;
  width: 100%;
}

.slick-dots li {
  display: inline-block;
  margin-right: .2rem;
  position: relative;
  padding: 3px;
  cursor: pointer;
  border: 0;
}

.slick-dots li:last-child {
  margin-right: 0;
}

.slick-dots button {
  text-indent: -99999px;
  font-size: 0;
  border: 0;
  height: 3px;
  width: 6px;
  background: #fff;
  height: 6px;
  padding: 0;
  vertical-align: top;
  -webkit-border-radius: 0;
  border-radius: 50%;
  border: 50%;
}

.slick-dots .slick-active button {
  background: #AF1515;
}

.slick-disabled,
.slick-disabled:hover {
  opacity: 0.3;
  cursor: default;
  pointer-events: none;
}

.slick-disabled:before,
.slick-disabled:hover:before {
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
}
