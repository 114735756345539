/* reset */
$min-width: 320px; 
$max-width:1380px; 
$base-font-size: 1.6rem; 
$base-line-height: 1.5; 
$base-font-family: 'Stem', Arial, sans-serif;
$custom-font-family: $base-font-family; 
$default-transition:all 0.3s ease-in;

/* main variables */

//text, titles
$text-color:  #3B3B3B;
$text-color-light: #FCFCFD;
$title-color: $text-color;
$title-font-family: $base-font-family;
$color-active: #AF1515;

$color1: #B1B5BE;
$color2: #504E4F;
$green: #58BD7D;
$red: #FF6838;
$link-color: $color-active;
$link-color-hover: $color-active;
$base-bg: #fff;
$placeholder:#fefefe;

$font-size-h1: 36px;
$line-height-h1: 42px;
$font-size-h2: 32px;
$line-height-h2: 37px;
$font-size-h3: 28px;
$line-height-h3: 32px;
