.main-wrapper {
	padding: 0;
	min-width: 320px;
	width: 100%;
	position: relative;
	overflow: hidden;
	min-height: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-transition: opacity .3s ease-out;
	-o-transition: opacity .3s ease-out;
	transition: opacity .3s ease-out;
}

.wrapper {
	min-width: 320px;
	max-width: 1300px;
	padding-right: 5rem;
	padding-left: 5rem;
	margin: 0 auto;
	position: relative;
	width: 100%;
}

.slider-wrapper {
	padding-left: calc(50% - 63.9rem);
}

/* titles */
p {
	padding: 1.2rem 0;
}

h2 {
	font-weight: 400;
}

h1 {
	margin-bottom: 2.5rem;
}

.h1 {
	font-weight: 700;
	font-size: 3.6rem;
	line-height: 4.4rem;
	text-transform: uppercase;
	color: #365370;
	margin-bottom: 4.1rem;
	position: relative;
	z-index: 1;
}


.h2,
.h2-decor {
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 21px;
	margin-bottom: 1.1rem;
	text-transform: uppercase;
}

.h2-decor {
	display: inline-block;
	padding: 1rem 2rem;
	margin: 0;
	background: linear-gradient(90deg, $color-active 0%, rgba(107, 17, 17, 0) 97.56%);
}

.section-subtitle {
	font-family: "Montserrat", arial, sans-serif;
	font-size: 1.7rem;
	line-height: 2.1rem;
	color: #93999E;
	margin-bottom: 1.5rem;
}

/* text position */
.text-left {
	text-align: left !important;
}

.text-center {
	text-align: center !important;
}

.text-right {
	text-align: right !important;
}

.nowrap {
	white-space: nowrap !important;
}

.center {
	text-align: center;
}

.loaded .main-wrapper {
	opacity: 0;
}

/*
================================================================================
|                                     CONTENT                                 |
================================================================================
*/
.content {
	min-width: 320px;
	text-align: left;
	width: 100%;
	-webkit-box-ordinal-group: 3;
	-ms-flex-order: 2;
	order: 2;
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	padding-top: 11.1rem;
	position: relative;
	z-index: 4
}

.btn {
	background: $color-active;
	border-radius: .8rem;
	border: 1px solid $color-active;
	font-family: "Halvar Breitschrift", arial, sans-serif;
	cursor: pointer;
	height: 4.4rem;
	display: inline-block;
	font-weight: 500;
	font-size: 1.6rem;
	line-height: 4.4rem;
	text-align: center;
	padding: 0 1.5rem;
	color: #fff;
	-webkit-transition: background .5s ease, border-color .5s ease;
	-o-transition: background .5s ease, border-color .5s ease;
	transition: background .5s ease, border-color .5s ease, color .5s ease;
}

.btn-2 {
	color: $color-active;
	border-color: #fff;
	background: #fff;
}

.btn:hover {
	background: #6B1111;
	border-color: $color-active;
	color: #fff;
}

.btn-2:hover {
	background: #333;
	border-color: #D4D4D4;
	color: #fff;
}

.btn-3 {
	background: #0A0A0A;
	color: #fff;
	border: 1px solid #FFFFFF;
}

.form-control {
	color: rgba(#D4D4D4, .5);
	height: 54px;
	padding-left: 2.6rem;
	padding-right: 2.6rem;
	font-size: 18px;
	font-family: "HalvarEng", arial, sans-serif;
	width: 100%;
	background: #0F0F0F;
	border: 1px solid #333;
	border-radius: 8px;
	transition: border-color .3s ease-in;
}

.form-control:focus {
	border-color: #D4D4D4;
}

.form-control_md {
	height: 4.6rem;
}

.form-control_md:focus {
	border-color: #fff;
}

.form-control.error {
	border-color: $color-active
}

::-webkit-input-placeholder {
	color: rgba(212, 212, 212, .4);
}

::-moz-placeholder {
	color: rgba(212, 212, 212, .4);
}

:-moz-placeholder {
	color: rgba(212, 212, 212, .4);
}

:-ms-input-placeholder {
	color: rgba(212, 212, 212, .4);
}

label.error {
	color: $color-active;
	font-size: 14px;
	line-height: 16px;
	margin: 0 1.5rem 0 0;
	display: block;
}

.checkbox-element label.error {
	position: absolute;
	left: 0;
	top: -2.7rem;
	margin: 0;
	white-space: nowrap;
}

.container label.error {
	margin: 0;
	white-space: nowrap;
}

.box-field {
	margin-bottom: 20px;
}

.box-field__label {
	font-size: 18px;
	margin-bottom: 9px;
}

.box-field_checkbox {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.web .box-field_checkbox a:hover {
	opacity: 1;
}

.scrollDisabled {
	overflow: hidden;
	position: fixed !important;
	margin-top: 0;
	width: 100%;
	z-index: 0;
}

.scrollDisabled.web,
.scrollDisabled.web.compensate-for-scrollbar {
	padding-right: 1.7rem;
}

.scrollDisabled .main-wrapper {
	overflow: visible;
}

.avatar {
	display: inline-block;
	width: 10.9rem;
	height: 10.9rem;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: 50%;
}


.tabs-wrapper {
	position: relative;
}

.tab {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	opacity: 0;
	pointer-events: none;
	-webkit-transition: all .1s ease;
	-o-transition: all .1s ease;
	transition: all .1s ease;
	z-index: -1;
}

.tab.active {
	position: relative;
	opacity: 1;
	z-index: 1;
	pointer-events: auto;
	-webkit-transition: all 0.8s ease;
	-o-transition: all 0.8s ease;
	transition: all 0.8s ease;
}

.checkbox-element {
	cursor: pointer;
	position: relative;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
}

.checkbox-element input {
	-ms-flex-negative: 0;
	flex-shrink: 0;
	display: inline-block;
	margin-top: 0;
	position: absolute;
	left: 0;
	top: 0;
	opacity: 0;
}

.checkbox-element input:checked~.check-label .check:after {
	opacity: 1;
}

.checkbox-element_2 .check:after {
	width: 10px;
	height: 10px;
	background: #28D88D;
	-webkit-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);
	border-radius: 3px;
}

.checkbox-element_2 .check-text {
	font-size: 1.4rem;
	line-height: 1.7rem;
	margin-left: 2.1rem;
}

.check {
	position: relative;
	display: block;
	width: 2.2rem;
	height: 2.2rem;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	border-radius: .4rem;
	position: relative;
	left: 0;
	top: -2px;
	padding: 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	background: #FFFFFF;
	-webkit-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);
	border-radius: 0.5rem;
	-ms-flex-negative: 0;
	flex-shrink: 0;
}

.check:after {
	content: '';
	opacity: 0;
	-webkit-transition: opacity .15s ease-in;
	-o-transition: opacity .15s ease-in;
	transition: opacity .15s ease-in;
	line-height: 1;
}

.check-label {
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.check-text {
	margin-left: 14px;
	font-size: 18px;
	line-height: 21px;
	color: rgba(56, 58, 60, 0.7);
}

.catalog-item {
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.catalog-item__icon {
	width: 3.2rem;
	height: 3.2rem;
	border-radius: .4rem;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.catalog-item__text {
	font-size: 1.5rem;
	line-height: 1.8rem;
	color: #383A3C;
	display: inline-block;
	margin-left: 1.2rem;
	-webkit-transition: color .3s ease;
	-o-transition: color .3s ease;
	transition: color .3s ease;
}

.catalog-item.current .catalog-item__text {
	color: #28D88D;
}

.web .catalog-item:hover .catalog-item__text {
	color: #28D88D;
}

.hide {
	display: none;
}

.box-field__input {
	position: relative;
}

.label-placeholder {

	position: absolute;
	left: 16px;
	top: 17px;
	pointer-events: none;
	color: #D4D4D4;
	opacity: 0.5;
	font-size: 18px;
	line-height: 21px;
	transition: all .5s ease;
}

.box-field__input_payment {
	.form-control {
		padding-left: 60px;
		padding-right: 109px;
	}
}

.box-field__input-icon {
	position: relative;

	.label-placeholder {
		left: 55px;
	}
}

.filled {
	.t-inp {
		padding-right: 75px;
	}

	.label-placeholder {
		left: 100%;
		transform: translateX(-100%);
		margin-left: -20px;
		top: 0;
		bottom: 0;
		display: flex;
		align-items: center;
		font-size: 12px;
		line-height: 14px;
	}

	.box-field__input-icon {
		.label-placeholder {
			left: 100%;
		}
	}
}

.box-field__input-icon .form-control {
	padding-left: 53px;
}

.box-field__icon {
	position: absolute;
	left: 15px;
	top: 16px;
	color: #333;
	font-size: 24px;
	z-index: 2
}

.note-popup-link {
	margin: -18px 0 14px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.password-link {
	margin-left: auto;
	font-size: 14px;
	line-height: 16px;
	color: #D4D4D4;
	border-bottom: 1px solid rgba(255, 255, 255, .8);
	flex-shrink: 0
}

.password-link:hover {
	border-bottom-color: transparent;


}

@media screen and (min-width: 768px) {

	.mob-show,
	.bars-mob_cross {
		display: none;
	}

}

@media screen and (min-width: 1024px) {
	.tablet-show {
		display: none !important;
	}
}

@media screen and (min-width: 1024px) and (max-height: 700px) {
	.arrow-scroll {
		bottom: 0;
	}
}

@media screen and (max-width: 1365px) {
	.wrapper {
		padding-left: 2.5rem;
		padding-right: 2.5rem;
	}
}


@media screen and (max-width: 1023px) {
	.tablet-hide {
		display: none !important;
	}
}


@media screen and (max-width: 767px) {
	.mob-hide {
		display: none;
	}

	.wrapper {
		padding-left: 1.5rem;
		padding-right: 1.5rem;
	}

	.check {
		width: 1.8rem;
		height: 1.8rem;
	}

	.checkbox-element_2 .check:after {
		width: 0.8rem;
		height: 0.8rem;
	}

	.h2-decor {
		padding: 4px 10px;
		font-size: 16px;
		line-height: 18px;
	}
}